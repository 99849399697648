import { IconographyNames } from '@vizir-banking/design-system';
import { BannerTypes, TagSizes, TagTypes, TagVariants } from '@vizir-banking/design-system/web';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { OrderStatusEnum, RoleEnum, ScopeEnum, SplitModeEnum } from '~/api/constants';
import { OrderDetails } from '~/api/requests';
import { FeedbackBanner } from '~/components/feedback-banner/feedback-banner';
import { OrderSummary } from '~/components/order-summary/order-summary';
import { LeadingContentType, PageHeader, TrailingContentType } from '~/components/page-header/page-header';
import { OrderEditSheetModal } from '~/components/react-spreadsheet/order-edit/order-edit';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { selectCurrentUser } from '~/redux/reducers/user';
import { FragmentCol } from '~/styles/global-styles';
import { designSystemTheme } from '~/styles/theme/design-system-theme';
import { useTranslation } from '~/translates/use-translate';

import { RechargeSteps } from '../types';
import {
  ButtonWrapper,
  Card,
  Container,
  MainButton,
  SendEmailDialog,
  SendStatementDialog,
  SideButton,
  SideButtonWrapper,
  SuccessDialog,
  Title,
} from './recharge-details.styles';
import { ButtonProps, IRechargeDetailsLayout, PossibleContents } from './types';

export const RechargeDetailsLayout = ({
  onChangeStep,
  order,
  data,
  isLoading,
  isSendEmailModalVisible,
  toggleSendEmailModal,
  toggleReleaseOrderModal,
  sendEmail,
  isSendEmailLoading,
  isSuccessModalVisible,
  toggleSuccessModal,
  isCanceledModalVisible,
  toggleCanceledModal,
  isCancelOrderLoading,
  handleCancelOrder,
  isSendStatementModalVisible,
  toggleSendStatementModal,
  isSendStatementLoading,
  sendStatement,
  onCloseEditOrder,
  onOpenEditOrder,
  showEditOrder,
  isFieldsLoading,
  fields,
}: IRechargeDetailsLayout): ReactElement => {
  const { hasScope, isRole } = useUserScopes();
  const translate = useTranslation('pages.rh.recharge.rechargeDetails');
  const userEmail = useSelector(selectCurrentUser)?.email;

  const getTranslatedStatus = (status: string): string => {
    const statusMap: { [key: string]: string } = {
      [OrderStatusEnum.PROCESSED]: 'approved',
      [OrderStatusEnum.PARTIAL_PROCESSED]: 'partial',
      [OrderStatusEnum.READY_TO_RELEASE]: 'ready',
      [OrderStatusEnum.PENDING_PAYMENT]: 'pending',
      [OrderStatusEnum.CANCELED]: 'canceled',
      [OrderStatusEnum.FAILED]: 'failed',
      [OrderStatusEnum.PROCESSING]: 'processing',
      [OrderStatusEnum.PROCESSING_SPREADSHEET]: 'processingSpreadsheet',
      [OrderStatusEnum.SPREADSHEET_FAILURE]: 'spreadsheetFailure',
      [OrderStatusEnum.UNKNOWN]: 'unknown',
    };
    return translate(`orderStatusCodes.${statusMap[status]}`);
  };

  const getStatusTagType = (status: string) => {
    const tagTypes: { [key: string]: TagTypes } = {
      [OrderStatusEnum.PROCESSED]: TagTypes.success,
      [OrderStatusEnum.PARTIAL_PROCESSED]: TagTypes.success,
      [OrderStatusEnum.READY_TO_RELEASE]: TagTypes.info,
      [OrderStatusEnum.PENDING_PAYMENT]: TagTypes.warning,
      [OrderStatusEnum.CANCELED]: TagTypes.negative,
      [OrderStatusEnum.FAILED]: TagTypes.neutral,
      [OrderStatusEnum.PROCESSING]: TagTypes.warning,
      [OrderStatusEnum.PROCESSING_SPREADSHEET]: TagTypes.warning,
      [OrderStatusEnum.SPREADSHEET_FAILURE]: TagTypes.neutral,
      [OrderStatusEnum.UNKNOWN]: TagTypes.neutral,
    };

    return tagTypes[status];
  };

  const hasContent = (key: PossibleContents): boolean => {
    const contentMap: { [key in PossibleContents]: string[] } = {
      [PossibleContents.EMPLOYEES_QTY]: [OrderStatusEnum.PROCESSED, OrderStatusEnum.PARTIAL_PROCESSED],
      [PossibleContents.SEND_EMAIL]: [
        OrderStatusEnum.PROCESSED,
        OrderStatusEnum.PARTIAL_PROCESSED,
        OrderStatusEnum.PROCESSING,
        OrderStatusEnum.READY_TO_RELEASE,
        OrderStatusEnum.FAILED,
        OrderStatusEnum.CANCELED,
        OrderStatusEnum.PENDING_PAYMENT,
      ],
      [PossibleContents.SEND_STATEMENT]: [
        OrderStatusEnum.PROCESSED,
        OrderStatusEnum.PARTIAL_PROCESSED,
        OrderStatusEnum.FAILED,
      ],
      [PossibleContents.RELEASE_ORDER]: [OrderStatusEnum.READY_TO_RELEASE],
      [PossibleContents.STILL_PROCESSING]: [
        OrderStatusEnum.PROCESSING,
        OrderStatusEnum.PROCESSING_SPREADSHEET,
      ],
      [PossibleContents.PAY_ORDER]: [OrderStatusEnum.PENDING_PAYMENT],
      [PossibleContents.CANCEL_ORDER]: [OrderStatusEnum.PENDING_PAYMENT],
      [PossibleContents.EDIT_ORDER]: [OrderStatusEnum.PENDING_PAYMENT],
    };

    return contentMap[key].includes(order.status);
  };

  const renderHeader = () => {
    const tagProps = {
      tagProps: {
        label: getTranslatedStatus(order.status),
        size: TagSizes.large,
        type: getStatusTagType(order.status),
        variant: TagVariants.primary,
      },
    };

    return (
      <PageHeader
        title={`${translate('order')} ${order.id}`}
        leadingContentType={LeadingContentType.GO_BACK}
        leadingGoBack={() => onChangeStep(RechargeSteps.BALANCE)}
        trailingContentType={TrailingContentType.TAG}
        trailingTag={tagProps}
      />
    );
  };

  const getFailQty = (data: OrderDetails): string => {
    let sum = 0;
    if (data.itemStatusCount?.failed) sum += data.itemStatusCount.failed;
    if (data.itemStatusCount?.notAck) sum += data.itemStatusCount.notAck;
    return sum.toString();
  };

  const renderFeedbackBanners = (): ReactElement | undefined => {
    if (hasContent(PossibleContents.EMPLOYEES_QTY)) {
      return (
        <FragmentCol style={{ gap: 24 }}>
          {data.itemStatusCount?.loaded && (
            <FeedbackBanner
              title={`${data.itemStatusCount?.loaded} ${translate('balancesSent')}`}
              type={BannerTypes.success}
            />
          )}

          {(data.itemStatusCount?.failed != 0 || data.itemStatusCount?.notAck != 0) && (
            <FeedbackBanner
              title={`${getFailQty(data)} ${translate('balancesFailed')}`}
              type={BannerTypes.error}
            />
          )}

          {data.itemStatusCount?.processing != 0 && (
            <FeedbackBanner title={`${translate('stillProcessing')}`} type={BannerTypes.warning} />
          )}
        </FragmentCol>
      );
    }

    return undefined;
  };

  const renderButtons = () => {
    const hasBoth =
      hasContent(PossibleContents.RELEASE_ORDER) ||
      hasContent(PossibleContents.PAY_ORDER) ||
      (hasContent(PossibleContents.SEND_STATEMENT) && hasContent(PossibleContents.SEND_EMAIL));
    return (
      <ButtonWrapper hasBoth={hasBoth}>
        <SideButtonWrapper>
          {hasContent(PossibleContents.RELEASE_ORDER) && (
            <SideButton
              label={translate('releaseOrder')}
              onClick={() => {
                if (toggleReleaseOrderModal) toggleReleaseOrderModal();
              }}
              isDisabled={isLoading}
              isLoading={isLoading}
            />
          )}
          {hasContent(PossibleContents.PAY_ORDER) && !isRole(RoleEnum.VIEWER) && (
            <SideButton
              label={translate('payOrder')}
              onClick={() => onChangeStep(RechargeSteps.PAYMENT_TYPE)}
              isDisabled={isLoading}
              isLoading={isLoading}
            />
          )}
          {hasContent(PossibleContents.EDIT_ORDER) && order.splitMode !== SplitModeEnum.INSTANTANEOUS && (
            <SideButton
              label={translate('editOrder')}
              onClick={onOpenEditOrder}
              isDisabled={isLoading || isFieldsLoading}
              isLoading={isLoading || isFieldsLoading}
            />
          )}
          {hasContent(PossibleContents.CANCEL_ORDER) && hasScope(ScopeEnum.CANCEL_ORDER) && (
            <SideButton
              label={translate('cancelOrder')}
              onClick={() => {
                handleCancelOrder();
              }}
              isLoading={isCancelOrderLoading || isLoading}
              isDisabled={isLoading}
            />
          )}
          {hasContent(PossibleContents.SEND_STATEMENT) && (
            <SideButton
              label={translate('sendStatement')}
              onClick={() => toggleSendStatementModal()}
              withLeadingIcon
              leadingIconName={IconographyNames.bill}
              isDisabled={isLoading}
              isLoading={isLoading}
            />
          )}
        </SideButtonWrapper>
        {hasContent(PossibleContents.SEND_EMAIL) && (
          <MainButton
            label={translate('sendEmail')}
            onClick={() => toggleSendEmailModal()}
            isDisabled={isLoading}
            isLoading={isLoading}
          />
        )}
      </ButtonWrapper>
    );
  };

  const sendEmailPrimaryButtonProps: ButtonProps = {
    label: translate('sendEmailDialog.confirm'),
    onClick: () => sendEmail(),
    containerProps: {
      style: {
        width: '60%',
      },
    },
    isLoading: isSendEmailLoading,
  };

  const sendEmailTertiaryButtonProps: ButtonProps = {
    label: translate('sendEmailDialog.cancel'),
    onClick: () => toggleSendEmailModal(),
    containerProps: {
      style: {
        color: `${designSystemTheme.designSystem.palette.error.medium}`,
        width: '60%',
      },
    },
  };

  const sendStatementPrimaryButtonProps: ButtonProps = {
    label: translate('sendStatementDialog.confirm'),
    onClick: () => sendStatement(),
    containerProps: {
      style: {
        width: '60%',
      },
    },
    isLoading: isSendStatementLoading,
  };

  const sendStatementTertiaryButtonProps: ButtonProps = {
    label: translate('sendStatementDialog.cancel'),
    onClick: () => toggleSendStatementModal(),
    containerProps: {
      style: {
        color: `${designSystemTheme.designSystem.palette.error.medium}`,
        width: '60%',
      },
    },
  };

  const primarySuccessButtonProps: ButtonProps = {
    label: 'Ok',
    onClick: () => toggleSuccessModal(),
    containerProps: {
      style: {
        width: '60%',
      },
    },
  };

  const primaryCanceledButtonProps: ButtonProps = {
    label: 'Ok',
    onClick: () => onChangeStep(RechargeSteps.BALANCE),
    containerProps: {
      style: {
        width: '60%',
      },
    },
  };

  return (
    <Container>
      {renderHeader()}
      {renderFeedbackBanners()}
      <Card>
        <Title>{translate('orderSummary.title')}</Title>
        <OrderSummary data={data} isLoading={isLoading} />
      </Card>
      {renderButtons()}

      <SendEmailDialog
        isVisible={isSendEmailModalVisible}
        onOutsideClick={() => toggleSendEmailModal()}
        title={`${translate('sendEmailDialog.title')} ${userEmail}?`}
        primaryButtonProps={sendEmailPrimaryButtonProps}
        tertiaryButtonProps={sendEmailTertiaryButtonProps}
      >
        {translate('sendEmailDialog.content')}
      </SendEmailDialog>

      <SendStatementDialog
        isVisible={isSendStatementModalVisible}
        onOutsideClick={() => toggleSendStatementModal()}
        title={`${translate('sendStatementDialog.title')} ${userEmail}?`}
        primaryButtonProps={sendStatementPrimaryButtonProps}
        tertiaryButtonProps={sendStatementTertiaryButtonProps}
      >
        {translate('sendStatementDialog.content')}
      </SendStatementDialog>

      <SuccessDialog
        isVisible={isSuccessModalVisible}
        onOutsideClick={() => toggleSuccessModal()}
        title={translate('successDialog.title')}
        primaryButtonProps={primarySuccessButtonProps}
      />

      <SuccessDialog
        isVisible={isCanceledModalVisible}
        onOutsideClick={() => {
          toggleCanceledModal();
          onChangeStep(RechargeSteps.BALANCE);
        }}
        title={translate('canceledDialog.title')}
        primaryButtonProps={primaryCanceledButtonProps}
      />

      <OrderEditSheetModal
        mode={order.splitMode}
        id={order.id}
        fields={fields}
        isOpen={showEditOrder}
        onClose={async () => onCloseEditOrder()}
      />
    </Container>
  );
};
