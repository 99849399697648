import { IconographyNames } from '@vizir-banking/design-system';

import { ScopeEnum, SplitModeEnum } from '~/api/constants';
import {
  ColumnDto,
  Order,
  OrderJsonDto,
  ScopeEnum as ScopeTypes,
  SplitModeEnum as SplitModeType,
} from '~/api/requests';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';
import { FilePickerModal } from '~/components/file-picker/file-picker-modal';
import { OrderEditSheetModal } from '~/components/react-spreadsheet/order-edit/order-edit';
import { DynamicTable } from '~/components/tables/table.layout';
import { ActionsTable, StatusTable } from '~/components/tables/types';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { RechargeSteps } from '../types';
import { PageHeader, Tabs } from './balance-recharge.styles';

interface IBalanceRecharge {
  recharges: Order[];
  tab: number;
  mode: SplitModeType;
  isLoading: boolean;
  isOnEndLoading: boolean;
  onChangeTab: (tabIndex: number) => void;
  onSelectOrder?: (order: Order) => void;
  onChangeStep: (step: RechargeSteps) => void;
  onToggleReleaseOrderModal?: (id?: string) => void;
  hasScope: (scope: ScopeTypes) => boolean;
  showAddRecharge: boolean;
  handleShowAddRecharge: (value: boolean) => void;
  onSubmitRecharge: (data: OrderJsonDto[]) => Promise<void>;
  fields: ColumnDto[];
  showEditOrder: boolean;
  orderId: string;
  onOpenEditOrder: (row: Order) => void;
  onCloseEditOrder: () => void;
}

export const BalanceRechargeLayout = ({
  recharges,
  tab,
  mode,
  isLoading,
  isOnEndLoading,
  onChangeTab,
  onChangeStep,
  onSelectOrder,
  onToggleReleaseOrderModal,
  hasScope,
  showAddRecharge,
  handleShowAddRecharge,
  onSubmitRecharge,
  fields,
  onCloseEditOrder,
  onOpenEditOrder,
  showEditOrder,
  orderId,
}: IBalanceRecharge) => {
  const translate = useTranslation('pages.rh.recharge.balanceRecharge');
  const tabLabels = ['all', 'done', 'pending', 'ready', 'cancelled'];

  const renderHeader = () => {
    return (
      <PageHeader
        title={translate('subtitle')}
        verifyScope={{
          scope: ScopeEnum.CREATE_ORDER,
        }}
        trailingButton={{
          label: translate('button'),
          onClick: () => handleShowAddRecharge(true),
          leadingIconName: IconographyNames.add,
        }}
      />
    );
  };

  const renderContent = () => {
    return (
      <DynamicTable<Order>
        data={recharges}
        columns={['status', 'createdAt', 'amount', 'splitMode']}
        isLoading={isLoading}
        isReachEndLoading={isOnEndLoading}
        isOrderTable
        actions={[
          {
            label: ActionsTable.VIEW_DETAILS,
            onCondition: () => hasScope(ScopeEnum.VIEW_DETAIL_ORDER),
            onClick: async (row) => {
              onSelectOrder && onSelectOrder(row);
              onChangeStep(RechargeSteps.RECHARGE_DETAILS);
            },
          },
          {
            label: ActionsTable.RELEASE_ORDER,
            onCondition: (row) =>
              row.status === StatusTable.ORDER.READY_TO_RELEASE && hasScope(ScopeEnum.SPLIT_ORDER),
            onClick: async (row) => {
              onSelectOrder && onSelectOrder(row);
              onToggleReleaseOrderModal && onToggleReleaseOrderModal();
            },
          },
          {
            label: ActionsTable.EDIT_ORDER,
            onCondition: (row) =>
              row.status === StatusTable.ORDER.PENDING_PAYMENT &&
              row.splitMode !== SplitModeEnum.INSTANTANEOUS,
            onClick: async (row) => {
              onOpenEditOrder(row);
            },
          },
        ]}
      />
    );
  };

  const renderTabs = tabLabels.map((label) => ({
    label: translate(`tabs.${label}`),
    element: renderContent(),
  }));

  return (
    <FragmentCol>
      {renderHeader()}
      <Tabs onChange={onChangeTab} items={renderTabs} activeTabIndex={tab} />

      <FilePickerModal<OrderJsonDto>
        isOpen={showAddRecharge}
        onClose={() => handleShowAddRecharge(false)}
        fields={fields}
        onSubmit={onSubmitRecharge}
      />

      <OrderEditSheetModal
        mode={mode}
        id={orderId}
        fields={fields}
        isOpen={showEditOrder}
        onClose={onCloseEditOrder}
      />
      <SuccessDialog />
      <ErrorDialog />
    </FragmentCol>
  );
};
