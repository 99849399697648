import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { BenefitsState } from '../types';

type ApplicationState = {
  error: string;
  success: string;
  isUnathorized: boolean;
};

const initialState: ApplicationState = {
  error: '',
  success: '',
  isUnathorized: false,
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = '';
    },
    setSuccess: (state, action: PayloadAction<string>) => {
      state.success = action.payload;
    },
    clearSuccess: (state) => {
      state.success = '';
    },
    setUnauthorized: (state) => {
      state.isUnathorized = true;
    },
    clearUnathorized: (state) => {
      state.isUnathorized = false;
    },
  },
});

export const { setError, clearError, setSuccess, clearSuccess, setUnauthorized, clearUnathorized } =
  applicationSlice.actions;

export const error = (state: BenefitsState) => state.application.error;
export const success = (state: BenefitsState) => state.application.success;
export const unathorized = (state: BenefitsState) => state.application.isUnathorized;

export default applicationSlice.reducer;
