import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { OrderEnum, SortLegalPersonEnum } from '~/api/constants';
import {
  useAuthServiceAuthControllerLoginBySwithRole,
  useEmployerServiceEmployerControllerListEmployees,
} from '~/api/queries';
import { OpenAPI, ScopeEnum, SimplifiedEmployers } from '~/api/requests';
import { ISSUER_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/issuer-portal';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { setIsRh, setIsSwitchingPortals } from '~/redux/reducers/portal';
import { setIssuerToken, setToken } from '~/redux/reducers/user';

interface IHomeHook {
  companies: SimplifiedEmployers[];
  isLoading: boolean;
  onViewDetails: (company: SimplifiedEmployers) => void;
  onAccessPortal: (company: SimplifiedEmployers) => Promise<void>;
  goToNewEmployer: () => void;
  hasScope: (scope: ScopeEnum) => boolean;
}

const COMPANIES_PER_PAGE = 6;

export const useHome = (): IHomeHook => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { hasScope } = useUserScopes();

  const onSwitchRole = useAuthServiceAuthControllerLoginBySwithRole({
    onSuccess: async (session) => {
      dispatch(setIsSwitchingPortals(true));

      await Promise.all([
        dispatch(setIsRh(true)),
        dispatch(setIssuerToken()),
        dispatch(setToken(session.token)),
      ]);

      OpenAPI.HEADERS = {
        ...OpenAPI.HEADERS,
        Authorization: `Bearer ${session.token}`,
      };

      navigate(RH_SCREENS_DEFINITIONS.rhHome);
    },
  });
  const { data: companies, isPending } = useEmployerServiceEmployerControllerListEmployees(
    {
      perPage: COMPANIES_PER_PAGE,
      sort: SortLegalPersonEnum.CREATEDAT,
      order: OrderEnum.DESC,
    },
    ['employerList'],
    { staleTime: 0 }
  );

  const onViewDetails = (company: SimplifiedEmployers) => {
    navigate(ISSUER_SCREENS_DEFINITIONS.details, {
      state: {
        company,
      },
    });
  };

  const goToNewEmployer = () => navigate(ISSUER_SCREENS_DEFINITIONS.new);

  const onAccessPortal = async (company: SimplifiedEmployers) => {
    await onSwitchRole.mutateAsync({
      requestBody: {
        company: company.personId,
      },
    });
  };

  return {
    companies: companies?.data ?? [],
    isLoading: isPending,
    onViewDetails,
    onAccessPortal,
    goToNewEmployer,
    hasScope,
  };
};
