import { MaritalStatusEmployeeEnum } from '~/api/constants';

export const ptBrRhPageTranslations = {
  rh: {
    employees: {
      view: {
        pageHeader: {
          currentStep: 'Colaboradores',
          subtitle: 'Colaboradores',
          button: 'Enviar listagem',
        },
        title: 'Colaboradores',
        tabs: {
          all: 'Todos',
          active: 'Ativos',
          inactive: 'Inativos',
        },
        addCollaborator: 'Cadastrar colaborador',
        error: {
          document: 'Documento inválido',
          notFound: 'Colaborador não encontrado',
        },
        sendEmailDialog: {
          title: 'Deseja enviar planilha com os colaboradores para o e-mail {{email}}?',
          content:
            'Esse processo pode demorar alguns minutos, mas não se preocupe. Você receberá em breve. Caso contrário, verifique sua caixa de spam.',
          confirm: 'Enviar',
          cancel: 'Cancelar',
        },
        successDialog: {
          title: 'E-mail enviado com sucesso!',
        },
      },
      newCollaborators: {
        pageHeader: {
          previousStep: 'Recarga de saldo',
          currentStep: 'Nova recarga',
          subtitle: {
            send: 'Cadastro',
            review: 'Revisão',
            validation: 'Validação',
            success: 'Tudo pronto!',
          },
        },
        tabs: {
          send: {
            title: '1. Envio do pedido',
            sheetModel: {
              title:
                'Para realizar o cadastro, você precisar utilizar o modelo de planilha fornecido. Você pode cadastrar a partir de 1 pessoa colaboradora.',
              button: 'Baixar planilha modelo',
            },
            divider: 'ou, caso você já tenha a planilha pronta, faça o envio',
            fileArea: {
              title: 'Envie sua planilha',
              formats: 'O arquivo precisa ser no formato csv.',
              button: 'Selecionar arquivo',
              paragraph: 'ou arraste e solte aqui',
            },
            buttons: {
              cancel: 'Cancelar',
              next: 'Próximo',
            },
          },
          review: {
            title: '2. Revisão',
            buttons: {
              back: 'Voltar',
              next: 'Enviar',
            },
            table: {
              line: 'Linha',
            },
          },
          validation: {
            title: '3. Validação',
            buttons: {
              resendFile: 'Enviar planilha novamente',
              back: 'Voltar',
              next: 'Próximo',
            },
            table: {
              line: 'Linha',
              status: 'Status',
            },
            modal: {
              title: 'Aviso: Informações sobre CPFs Recusados.',
              subtitle:
                'Ao continuar, apenas os CPFs aprovados serão cadastrados. Os CPFs recusados serão registrados na base com o status "Recusado", mas poderão ser reavaliados caso uma nova planilha contendo esses CPFs seja enviada.',
              buttons: {
                cancel: 'Cancelar',
                next: 'Continuar',
              },
            },
          },
          success: {
            title: '4. Tudo pronto!',
            heading: 'Cadastro finalizado',
            dialog: 'com sucesso!',
            button: {
              collaborators: 'Ver lista de colaboradores',
            },
          },
        },
      },
      profile: {
        pageHeader: {
          previousStep: 'Colaboradores',
        },
        personalInfo: {
          title: 'Informações pessoais',
          fields: {
            name: 'Nome completo',
            preferredName: 'Nome social',
            emancipatedMinor: 'Menor emancipado?',
            birthDate: 'Data de nascimento',
            document: 'CPF',
            maritalStatus: 'Estado civil',
            motherName: 'Nome da mãe',
          },
        },
        contact: {
          title: 'Contato',
          fields: {
            email: 'E-mail',
            phoneNumber: 'Telefone',
          },
        },
        address: {
          title: 'Endereço',
          fields: {
            zipCode: 'CEP',
            street: 'Rua',
            country: 'País',
            neighborhood: 'Bairro',
            number: 'Número',
            complement: 'Complemento',
            city: 'Cidade',
            state: 'Estado',
          },
        },
        maritalStatus: {
          [MaritalStatusEmployeeEnum.DIVORCED]: 'Divorciado(a)',
          [MaritalStatusEmployeeEnum.MARRIED]: 'Casado(a)',
          [MaritalStatusEmployeeEnum.SINGLE]: 'Solteiro(a)',
          [MaritalStatusEmployeeEnum.WIDOWED]: 'Viúvo(a)',
          [MaritalStatusEmployeeEnum.UNKNOWN]: '-',
        },
        errors: {
          zipCode: 'CEP inválido',
        },
      },
    },
    listBatches: {
      title: 'Cadastros',
      addCollaborators: 'Cadastrar colaboradores',
      batchDetails: {
        batchNumber: 'Número do Lote',
        proposalsAccepted: 'CPFs aceitos',
        proposalsWithErrors: 'CPFs recusados',
        proposalsDeclined: 'CPFs não foram processados',
        downloadRejectedSpreadsheet: 'Detalhes dos recusados',
        batchDetails: 'Detalhes do lote',
        batchNotFound: 'Lote não encontrado',
      },
      tabs: {
        all: 'Todos',
        processing: 'Processando',
        processed: 'Processados',
      },
    },
    recharge: {
      releaseOrderModal: {
        title: 'Deseja confirmar a distribuição do saldo para os colaboradores?',
        content: 'Uma vez que o valor for distribuído, esta ação não poderá ser desfeita.',
        confirm: 'Distribuir',
        cancel: 'Cancelar',
      },
      balanceRecharge: {
        title: 'Colaboradores',
        subtitle: 'Recarga de saldo',
        tabs: {
          all: 'Todos',
          done: 'Distribuídas',
          cancelled: 'Canceladas',
          pending: 'Pagamento pendente',
          ready: 'Pronta para distribuir',
        },
        table: {
          status: 'Status',
          registerDate: 'Data da solicitação',
          amount: 'Valor',
          actions: 'Ações',
        },
        status: {
          approved: 'Liberada',
          ready: 'Pronto para liberar',
          pending: 'Pagamento pendente',
          canceled: 'Cancelado',
          failed: 'Falha',
        },
        button: 'Nova recarga',
      },
      newRecharge: {
        pageHeader: {
          previousStep: 'Recarga de saldo',
          currentStep: 'Nova recarga',
          subtitle: 'Nova recarga',
        },
        stepByStep: {
          step0: 'Envio da planilha',
          step1: 'Validação da planilha',
          step2: 'Forma de pagamento',
          step3: 'Forma de distribuição',
          step4: 'Pagamento',
        },
        tabs: {
          send: {
            title: 'Envio da planilha',
            sheetModel: {
              title:
                'Para realizar a recarga, sua planilha deve seguir o modelo fornecido. Você pode baixar nosso modelo de planilha clicando aqui *em baixar planilha modelo.*',
              button: 'Baixar planilha modelo',
            },
            divider: 'ou, caso você já tenha a planilha pronta, faça o envio',
            fileArea: {
              title: 'Envie sua planilha',
              formats: 'Os arquivos precisam ser no formato .csv',
              button: 'Selecionar arquivo',
              paragraph: 'ou arraste e solte aqui',
            },
            banners: {
              mayTakeAWhile:
                'A depender da quantidade de pedidos cadastrados na planilha, o processo de validação pode demorar alguns minutos.',
              maxItems: 'Envie até 10000 colaboradores por vez.',
            },
            buttons: {
              cancel: 'Cancelar',
              next: 'Continuar',
              validateOrder: 'Validar pedido',
            },
            redirectDialog: {
              title: 'Pedido criado com sucesso.',
              toList: 'Voltar para a lista de recargas',
            },
          },
          review: {
            title: 'Validação da planilha',
            buttons: {
              cancel: 'Cancelar',
              resend: 'Reenviar planilha',
            },
            errors: {
              errorsFound: 'Erros detectados. Corrija os erros e envie a planilha novamente.',
              line: 'Linha',
              duplicated_cpf: 'CPF duplicado',
              invalid_value: 'Valor inválido',
              not_found_cpf: 'CPF não encontrado',
            },
          },
          paymentType: {
            title: 'Forma de pagamento',
            summary: {
              title: 'Resumo do pedido',
            },
            options: {
              title: 'Selecione uma ou mais formas de pagamento',
              subtitle:
                'Você também pode pagar utilizando os Créditos que estejam disponíveis em sua carteira. O saldo será disponibilizado aos colaboradores após a confirmação do pagamento.',
              ted: {
                title: 'Pagar via TED',
                subtitle: '',
              },
              pix: {
                title: 'PIX',
                subtitle: 'Instantâneo',
              },
              billet: {
                title: 'Boleto bancário',
                subtitle: 'Até 3 dias úteis',
              },
              credits: {
                title: 'Créditos na carteira',
                subtitle: 'A confirmação do pagamento deverá ser confirmada em até 2 horas *todos os dias*.',
                currentBalance: 'Saldo atual:*{{balance}}*',
                hint: 'É possível usar os Créditos da sua conta em conjunto com TED',
              },
              total: 'Valor total a pagar',
            },
            buttons: {
              cancel: 'Cancelar',
              next: 'Continuar',
              goBack: 'Voltar',
            },
            totalValue: {
              base: 'Valor total a pagar',
              both: 'via TED + Créditos na carteira',
              ted: 'via TED',
              credits: 'via Créditos na carteira',
              discount: '(Desconto de Crédito na carteira)',
              bothOptionsBalance: '{{value}} - {{discount}} (Desconto de Crédito na carteira)',
              finalBalance: 'O seu saldo após confirmação será de: {{finalBalance}}',
              noBalance:
                'O seu saldo é insuficiente para completar o pagamento. Por favor, complete o valor restante via TED.',
            },
          },
          distributionType: {
            summary: {
              title: 'Resumo do pedido',
            },
            title: 'Quando o valor fica disponível para os colaboradores?',
            options: {
              schedule: 'Agendar para uma data específica',
              scheduleDate: 'Data do agendamento: ',
              scheduleDateWarning: 'Atente-se para não agendar a distribuição em dias de feriado',
              manual: 'Liberar manualmente após a compensação do pagamento',
              instantWeekDay: 'Em até 1 hora, em dias úteis, durante o horário comercial',
              instantWeekend: 'Em até duas 2 horas todos os dias',
            },
            datepicker: {
              months: {
                january: 'Janeiro',
                february: 'Fevereiro',
                march: 'Março',
                april: 'Abril',
                may: 'Maio',
                june: 'Junho',
                july: 'Julho',
                august: 'Agosto',
                september: 'Setembro',
                october: 'Outubro',
                november: 'Novembro',
                december: 'Dezembro',
              },
              weekdays: {
                sunday: 'Domingo',
                monday: 'Segunda-feira',
                tuesday: 'Terça-feira',
                wednesday: 'Quarta-feira',
                thursday: 'Quinta-feira',
                friday: 'Sexta-feira',
                saturday: 'Sábado',
              },
            },
            buttons: {
              goBack: 'Voltar',
              done: 'Confirmar',
            },
            totalValue: {
              base: 'Valor total a pagar',
              both: 'via TED + Créditos na carteira',
              ted: 'via TED',
              credits: 'via Créditos na carteira',
              bothOptionsFinal:
                'No valor final de: {{value}} - {{discount}} (Desconto de Crédito na carteira)',
              creditsOnlyFinal: 'No valor final de: {{value}} (Desconto de Crédito na carteira)',
            },
          },
          payment: {
            title: 'Pagamento',
            header: {
              title: 'Pague {{currency}} via {{method}}',
              subtitle: 'e o saldo será disponibilizado em até uma hora!',
            },
            items: {
              title: 'Dados da conta',
              agency: 'Agência',
              account: 'Conta',
              cnpj: 'CNPJ',
              bankNumber: 'Banco',
              legalName: 'Razão social',
            },
            goBack: 'Voltar para a lista de recargas',
          },
        },
      },
      rechargeDetails: {
        order: 'Pedido: ',
        registeredSpreadsheet: 'Planilha cadastrada',
        balancesSent: 'saldos da planilha foram distribuídos',
        balancesFailed: 'saldos da planilha não foram distribuídos',
        stillProcessing: 'O pedido ainda está em processamento',
        sendEmail: 'Enviar detalhes por e-mail',
        sendStatement: 'Enviar extrato por e-mail',
        releaseOrder: 'Liberar saldo',
        payOrder: 'Pagar',
        cancelOrder: 'Cancelar',
        editOrder: 'Editar',
        orderSummary: {
          title: 'Resumo do pedido',
        },
        orderStatusCodes: {
          approved: 'Distribuído completo',
          partial: 'Distribuído em parte',
          ready: 'Pronta para distribuir',
          pending: 'Pagamento pendente',
          canceled: 'Cancelada',
          failed: 'Falha na distribuição',
          processing: 'Pedido em processamento',
          processingSpreadsheet: 'Processando planilha',
          spreadsheetFailure: 'Falha no processamento',
          unknown: 'Erro desconhecido',
        },
        sendEmailDialog: {
          title: 'Deseja enviar planilha com os detalhes do pedido para o e-mail',
          content:
            'Esse processo pode demorar alguns minutos, mas não se preocupe. Você receberá em breve. Caso contrário, verifique sua caixa de spam.',
          confirm: 'Enviar',
          cancel: 'Cancelar',
        },
        sendStatementDialog: {
          title: 'Deseja enviar o extrato do pedido para o e-mail',
          content:
            'Esse processo pode demorar alguns minutos, mas não se preocupe. Você receberá em breve. Caso contrário, verifique sua caixa de spam.',
          confirm: 'Enviar',
          cancel: 'Cancelar',
        },
        successDialog: {
          title: 'E-mail enviado com sucesso!',
        },
        canceledDialog: {
          title: 'Pedido cancelado com sucesso!',
        },
      },
    },
    permissions: {
      pageHeader: {
        title: 'Permissões',
        button: 'Adicionar permissão',
      },
      fields: {
        name: 'Nome completo',
        document: 'CPF',
        email: 'E-mail',
        role: {
          label: 'Permissão',
          options: {
            viewer: 'Visualizador',
            admin: 'Administrador',
            operator: 'Operador',
          },
        },
      },
      button: {
        cancel: 'Cancelar',
        send: 'Enviar',
      },
    },
    home: {
      pageHeader: {
        currentStep: 'Home Portal RH',
        subtitle: 'Boas-vindas!',
        switch: 'Trocar de Portal',
      },
      credits: {
        title: 'Crédito disponível',
        add: 'Adicionar créditos',
      },
      sheets: {
        title: 'Modelo de planilhas disponíveis',
        collaborators: 'Planilha de colaboradores',
        orders: 'Planilha de pedidos',
      },
      rechargeHistory: {
        title: 'Histórico de recargas',
        add: 'Nova recarga de saldo',
      },
      close: 'Fechar',
    },
    cards: {
      cardsList: {
        pageHeader: {
          title: 'Solicitar cartões',
          button: 'Solicitar cartões',
        },
        tabs: {
          all: 'Todos',
          delivered: 'Entregues',
          pending: 'Em processamento',
        },
      },
      newCards: {
        pageHeader: {
          title: 'Solicitar cartões',
        },
        tabs: {
          quantity: {
            title: 'Quantidade',
            fields: {
              quantity: {
                section: 'Quantos cartões você quer?',
                label: 'Quantidade de cartões',
                placeholder: 'Quantidade',
                assistiveText: 'Mínimo: 1 unidade | Máximo: 5.000 unidades.',
              },
              date: {
                label: 'Data de expiração: {{date}}',
              },
            },
            banner:
              'A data de expiração dos cartões será sempre calculada *como a data da solicitação acrescida de {{year}} anos.*',
            button: {
              cancel: 'Cancelar',
              next: 'Próximo',
            },
          },
          address: {
            title: 'Endereço',
            section: {
              title: 'Selecione o endereço de envio:',
              add: 'Adicionar novo endereço',
            },
            form: {
              title: 'Novo endereço',
              fields: {
                zipCode: 'CEP *',
                street: 'Rua *',
                neighborhood: 'Bairro *',
                number: 'Número *',
                complement: 'Complemento',
                city: 'Cidade *',
                state: 'Estado *',
              },
              button: {
                add: 'Adicionar novo endereço',
                cancel: 'Cancelar',
              },
            },
            button: {
              cancel: 'Cancelar',
              next: 'Próximo',
            },
          },
          review: {
            title: 'Revisão do pedido',
            row: {
              quantity: 'Quantidade de cartões solicitado',
              date: 'Data de expiração dos cartões',
            },
            section: {
              title: 'Revise as informação da solicitação:',
              summary: 'Resumo do pedido:',
              address: 'Endereço de envio:',
            },
            button: {
              cancel: 'Cancelar',
              confirm: 'Confirmar e solicitar',
            },
          },
        },
        button: {
          success: 'Ok',
          error: 'Entendi',
        },
      },
    },
    settings: {
      enterpriseAddress: {
        pageHeader: {
          text: 'Empresas',
          title: 'Configurações',
        },
        section: {
          title: 'Endereços da empresa',
          form: {
            createTitle: 'Novo endereço',
            editTitle: 'Editar endereço',
            fields: {
              zipCode: 'CEP *',
              street: 'Rua *',
              neighborhood: 'Bairro *',
              number: 'Número *',
              complement: 'Complemento',
              city: 'Cidade *',
              state: 'Estado *',
              isMain: 'Deixar esse endereço como principal',
            },
            button: {
              cancel: 'Cancelar',
              edit: 'Atualizar endereço',
              add: 'Adicionar novo endereço',
            },
          },
          add: 'Adicionar novo endereço',
        },
        delete: {
          title: 'Tem certeza que deseja excluir o endereço?',
          primary: 'Excluir endereço',
          secondary: 'Cancelar',
        },
      },
    },
    addCredit: {
      title: 'Crédito na carteira',
      moneyInput: {
        subtitle:
          'O Crédito na carteira é um valor que fica disponível dentro da plataforma para ser utilizado para o pagamento de pedidos de benefícios a qualquer momento.',
        balanceRules: {
          title: 'Regras de utilização do saldo',
          description:
            'O saldo disponível na carteira pode ser utilizado apenas para pedidos dos tipos Instantâneo ou Agendado. Caso o pedido seja do tipo Manual, o pagamento deverá ser feito com um TED de valor igual ao do pedido em aberto, a menos que já exista um pedido Instantâneo ou Agendado programado para a mesma data.',
          listTitle: 'Os pedidos seguem a seguinte ordem de prioridade:',
          list: {
            instantaneous: 'Instantâneo',
            scheduled: 'Agendado',
            manual: 'Manual',
          },
          conclusion:
            'Dessa forma, pedidos instantâneos e agendados são processados primeiro, antes dos manuais.',
        },
        fields: {
          amount: {
            label: 'Valor a ser adicionado',
            placeholder: '0,00',
            warning:
              '*Atenção!* A compensação do saldo acontece em até 1 hora, somente em dias úteis dentro do horário comercial.',
          },
        },
        payment: {
          currentBalance: 'Saldo atual: {{currency}}',
          amountToAdd: 'Valor a pagar: {{currency}}',
          totalBalance: 'Valor total após adicionar o crédito: {{currency}}',
        },
        buttons: {
          confirm: 'Finalizar',
          back: 'Voltar',
        },
      },
      paymentDetails: {
        title: 'Pagamento',
        header: {
          title: 'Pague *R$ {{currency}}* via {{method}}',
          subtitle: 'e o saldo será disponibilizado em até uma hora!',
        },
        items: {
          title: 'Dados da conta',
          agency: 'Agência',
          account: 'Conta',
          cnpj: 'CNPJ',
          bankNumber: 'Banco',
          legalName: 'Razão social',
        },
        goBack: 'Voltar para o início',
      },
    },
  },
};
