import { useQueryClient } from '@tanstack/react-query';
import { createContext, ReactElement, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorsEnum } from '~/api/constants';
import { OpenAPI } from '~/api/requests';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import {
  clearError,
  clearUnathorized,
  setError,
  setUnauthorized,
  unathorized,
} from '~/redux/reducers/application';
import { clearEmployer } from '~/redux/reducers/employer';
import { clearPortal } from '~/redux/reducers/portal';
import { clearCurrentUser } from '~/redux/reducers/user';
import { useTranslation } from '~/translates/use-translate';

type TokenExpirationContextType = {
  handleTokenExpiration: () => void;
};

const TokenExpirationContext = createContext<TokenExpirationContextType | undefined>(undefined);

export const useTokenExpirationContext = () => {
  const context = useContext(TokenExpirationContext);
  if (!context) {
    throw new Error('useTokenExpirationContext must be used within a TokenExpirationProvider');
  }
  return context;
};

export function TokenExpirationProvider({ children }: { children: ReactElement }) {
  const translate = useTranslation('errors');
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const isUnauthorized = useSelector(unathorized);

  const resetQueries = () => {
    queryClient.cancelQueries();
    queryClient.removeQueries();
    queryClient.resetQueries();
    queryClient.invalidateQueries();
  };

  const handleTokenExpiration = useCallback(() => {
    resetQueries();
    if (!isUnauthorized) {
      dispatch(setUnauthorized());
      dispatch(setError(ErrorsEnum.EXPIRED_TOKEN));
    }
  }, [dispatch, isUnauthorized, queryClient]);

  const handleRedirectToLogin = useCallback(async () => {
    resetQueries();
    OpenAPI.HEADERS = undefined;
    dispatch(clearCurrentUser());
    dispatch(clearEmployer());
    dispatch(clearPortal());
    dispatch(clearError());
    setTimeout(() => {
      dispatch(clearUnathorized());
    }, 5000);
  }, [dispatch, queryClient]);

  const secondaryButtonProps = {
    label: translate(`${ErrorsEnum.EXPIRED_TOKEN}.button`),
    onClick: handleRedirectToLogin,
  };

  return (
    <TokenExpirationContext.Provider value={{ handleTokenExpiration }}>
      {children}
      <ErrorDialog
        secondaryButtonProps={secondaryButtonProps}
        closeOnClickOutside={false}
        isTokenExpiration={true}
      />
    </TokenExpirationContext.Provider>
  );
}
